import React, { useContext, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SidebarLayout from "../components/shared/SidebarLayout";
import { useTranslation } from "react-i18next";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
const Profile = () => {
  const navigate = useNavigate();
  let { user, getProfileData, profileData } = useContext(AuthContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState({});
  const handleClose = () => {
    setEdit({ status: false });
  };
  const onSubmit = async () => {
    const { status, data } = await apiPost(apiPath.userProfileUpdate, {
      fullName: edit?.item,
    });
    if (status === 200) {
      if (data.success) {
        getProfileData();
        handleClose();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } else {
      toast.error(data?.message);
    }
  };
  return (
    <SidebarLayout heading={t("My_Profile")}>
      {" "}
      <div className="p-sm-3 p-2 inner-sidebar-content">
        <Table className="align-middle bg-white">
          <tbody>
            <tr>
              <td width="30%">{t("User_Name")}</td>
              <td width="70%" colSpan="2">
                {user?.user?.username}
              </td>
            </tr>
            <tr>
              <td width="30%">{t("Email")}</td>
              <td width="40%">{profileData?.firstName}</td>
              <td width="30%">
                <Button
                  onClick={() =>
                    setEdit({
                      ...edit,
                      status: true,
                      item: profileData?.firstName,
                    })
                  }
                  className="theme-btn py-1 px-3 fs-6"
                >
                  {t("Edit")}
                </Button>
              </td>
            </tr>
            {/* <tr>
          <td width="40%">Contact Number</td>
          <td width="60%" colSpan="2">{user?.user?.firstName}</td>
        </tr> */}
            <tr>
              <td width="30%">{t("Password")}</td>
              <td width="40%">********</td>
              <td width="30%">
                <Button
                  onClick={() => navigate("/change-password")}
                  className="theme-btn py-1 px-3 fs-6"
                >
                  {t("Edit")}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {edit?.status && (
        <Modal centered show={edit?.status} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Full Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className="w-100"
              placeholder="Enter Full Name"
              value={edit?.item}
              onChange={(e) => {
                setEdit({ ...edit, item: e.target.value });
              }}
              style={{
                borderRadius: "5px",
                padding: "5px",
                border: "1px solid grey",
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onSubmit}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </SidebarLayout>
  );
};

export default Profile;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
const Footer = () => {
  const location = useLocation();
  const { user, showSidebar, active, setFooterLink } = useContext(AuthContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [icon, setIcon] = useState({});
  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
  };

  const getData = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    const { data } = await apiGet(apiPath?.getIcon, {
      website: "betwoin.com"
    });
    if (data?.success) {
      setIcon(data?.results);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setCasinoModel(true);
    }
  };
  return (
    <>
      <div className="footer">
        {location?.pathname == "/" && !showSidebar && active == "sports" && (
          <div className="betbtn1">
            {icon?.whatsappIcon !== "" && (
              <div
                onClick={() => {
                  window.open(icon?.whatsappContent, "_self");
                }}
              >
                <img
                  src={process.env.REACT_APP_API_BASE_URL + icon?.whatsappIcon}
                  alt=""
                />
              </div>
            )}
            {icon?.telegramIcon !== "" && (
              <div
                onClick={() => {
                  // if (isEmpty(user)) {
                  //   navigate("/login");
                  // }
                  window.open(icon?.telegramContent, "_self");
                }}
              >
                <img
                  src={process.env.REACT_APP_API_BASE_URL + icon?.telegramIcon}
                  alt=""
                />
              </div>
            )}{" "}
            {icon?.livechatIcon !== "" && (
              <div
                onClick={() => {
                  window.open(icon?.livechatContent, "_self");
                }}
              >
                <img
                  src={process.env.REACT_APP_API_BASE_URL + icon?.livechatIcon}
                  alt=""
                />
              </div>
            )}
            {/* <Draggable onStart={handleTouchStart} onStop={handleTouchEnd}>
              <div className="betbtn2">
                <img
                  src="../../assets/images/sabas.png"
                  className="bounce-4"
                  alt=""
                />
              </div>
            </Draggable> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Footer;
